import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"
import * as S from "./style"
import { useState } from "react"
import api from "../../../../services"

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `40%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
}

export default function PushModal({ selected }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async () => {
    if (!title || !description) {
      alert("Por favor, preencha todos os campos.");
      return;
    }
    if (selected.length === 0) {
      alert("Por favor, selecione pelo menos um usuário.");
      return;
    }

    setLoading(true);
    try {
      for (const id of selected) {
        const response = await api.post(
          "/api/push-notification",
          {
            userId: id.toString(),
            title: title,
            body: description
          }
        )
      }
      alert("Notificações enviadas com sucesso!");
    } catch(error) {
      alert(`Falha ao enviar notificações: ${error}`);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <S.Button onClick={() => setOpen(true)}>
        Enviar Notificações
      </S.Button>
      {open && (
        <Modal
          open={open}
          onClose={setOpen}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <S.Icon>
              <CloseOutlinedIcon disabled={loading} onClick={() => setOpen(false)} />
            </S.Icon>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <S.ContainerTitle>
                <S.Text>Informe as Informações da Notificação</S.Text>
                <FormGroup>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Titulo</S.Subtitle>
                    <TextField
                      id="title"
                      type="title"
                      onChange={(e) => setTitle(e.target.value)}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 20,
                        },
                      }}
                      style={{
                        marginBottom: 10
                      }}
                    />
                  </S.SubtitleWrapper>
                  <S.SubtitleWrapper>
                    <S.Subtitle>Descrição</S.Subtitle>
                    <TextField
                      id="description"
                      type="description"
                      onChange={(e) => setDescription(e.target.value)}
                      inputProps={{
                        style: {
                          padding: 10,
                          height: 20,
                        },
                      }}
                    />
                  </S.SubtitleWrapper>
                </FormGroup>
                <S.BottomInfo>
                  <S.BottomButtons>
                    <S.OutlineButton disabled={loading} onClick={handleSubmit}>
                      <p>Enviar</p>
                    </S.OutlineButton>
                    <S.DefaultButton disabled={loading} onClick={() => setOpen(false)}>
                      <p>Cancelar</p>
                    </S.DefaultButton>
                  </S.BottomButtons>
                </S.BottomInfo>
              </S.ContainerTitle>
            </Typography>
          </Box>
        </Modal>
      )}
    </>
  )
}
