import styled from "styled-components";

export const AllContainer = styled.div`
  padding-top: 30px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ContentRow = styled.div`
  display: flex;
  gap: 30px;
`;

export const Section = styled.div`
  padding: 30px;
  border: 1px solid #ccf;
  border-radius: 8px;
  background-color: #CCCCFF;
  width: 100%;
`;

export const InformationGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  strong {
    font-weight: bold;
  }
`;

/* Estilo para a galeria de mídias */
export const MediaGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`;

export const MediaItem = styled.div`
  flex: 1 1 200px; /* Controla o tamanho mínimo */
  max-width: 300px; /* Define um tamanho máximo */
  
  img, video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  video {
    margin-top: 10px; /* Espaço extra para diferenciar do texto */
  }
`;
