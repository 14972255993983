import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Typography from "@mui/material/Typography"
import * as React from "react"
import * as S from "./style"

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `40%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
}

export default function ExportModal({
  exportModalOpen,
  setExportModalOpen,
  onExport,
}) {
  const [selectedFields, setSelectedFields] = React.useState([])
  
  const fields = [
    { label: "Nome", value: "name" },
    { label: "E-mail", value: "email" },
    { label: "Telefone", value: "telephone" },
    { label: "Telefone Celular", value: "cellphone" },
    { label: "Data de Criação", value: "created_at" },
  ]

  const handleClose = () => setExportModalOpen(false)

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target
    setSelectedFields((prev) =>
      checked ? [...prev, value] : prev.filter((field) => field !== value)
    )
  }

  const handleExport = () => {
    onExport(selectedFields)
  }

  return (
    <Modal
      open={exportModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>

        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <S.ContainerTitle>
            <S.Text>Selecione os dados para o CSV</S.Text>
            <FormGroup>
              {fields.map((field) => (
                <FormControlLabel
                  key={field.value}
                  control={
                    <Checkbox
                      value={field.value}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label={field.label}
                />
              ))}
            </FormGroup>
            <S.BottomInfo>
              <S.BottomButtons>
                <S.OutlineButton onClick={handleExport}>
                  <p>Exportar</p>
                </S.OutlineButton>
                <S.DefaultButton onClick={handleClose}>
                  <p>Cancelar</p>
                </S.DefaultButton>
              </S.BottomButtons>
            </S.BottomInfo>
          </S.ContainerTitle>
        </Typography>
      </Box>
    </Modal>
  )
}
