// ConsumerHome.js
import {
  Checkbox,
  CircularProgress,
} from "@mui/material"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import { useEffect, useState } from "react"
import MainLayout from "../../layouts/MainLayout"
import api from "../../services"
import { phoneMask } from "../../utils/masks"
import * as S from "./style"
import { Link } from "react-router-dom"
import PushModal from "./components/PushModal"

function PushNotification() {
  const [consumers, setConsumers] = useState([])
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getData();
    }, 1000);

    return () => clearTimeout(debounceTimeout);
  }, [search]);

  async function getData() {
    try {
      setLoading(true)
      const res = await api.get(
        "/api/admin/users/with-notification",
        search ? { params: { search }} : {}
      )

      setConsumers(res.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getData()
  }, []);

  const handleSelect = (id) => {
    setSelected(prev => {
      if (prev.includes(id)) {
        return prev.filter(s => s !== id);
      } else {
        return [...prev, id];
      }
    })
  }

  const handleSelectAll = () => {
    if (selected.length === consumers.length) {
      setSelected([]);
    } else {
      setSelected(consumers.map(c => c.id));
    }
  }

  return (
    <MainLayout title="Enviar Notificações Para Anunciantes">
      <S.Header>
        <S.Input
          placeholder="Busque pelo nome ou email"
          onChange={(e) => setSearch(e.target.value)}
        />
        <PushModal selected={selected}/>
      </S.Header>

      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#919DF2" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="consumer table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell style={{display: "inline-flex", alignItems: "center"}}>
                  <Checkbox 
                    checked={selected.length === consumers.length}
                    onChange={handleSelectAll}
                    style={{padding: 0}}
                  />
                  <p>Selecionar todos</p>
                </TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telefone</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {consumers?.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Checkbox 
                      checked={selected.includes(item.id)}
                      onChange={() => handleSelect(item.id)}
                      style={{padding: 0}}
                    />
                  </TableCell>
                  <TableCell component="th" scope="item">
                    <Link to={`/detail/consumidor/${item.id}`}>{item?.name ? item?.name : "-"}</Link>
                  </TableCell>
                  <TableCell>{item?.email ? item?.email : "-"}</TableCell>
                  <TableCell>
                    {item?.telefone ? phoneMask(item?.telefone) : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </MainLayout>
  )
}

export default PushNotification
